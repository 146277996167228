export const constant = {
  NOTREAD: 1,
  READED: 2,
  UNREAD_COUNT: 0,
  ISSYNC: 0,
  ISSYNCED: 1,
  SUCCESS: 'success',
  COMMENT: 'feed',
  MESSAGE: 'message',
  ALL:'all',
  CHAT:'chat',
  CUSTOMER:'customer',
  SETTING:'setting',
  LABELS:'labels',
  SCHEDULE_MANAGEMENT: 'ScheduleManagement',
  OTHERSETTING:'otherSetting',
  WORKSCHEDULEMANAGEMENT:'workScheduleManagement',
  ENABLE_WEBHOOK: 0,
  TURNOFF_WEBHOOK: 1,
  SEND_MESS_ERROR: 0,
  SEND_MESS_PENDDING: 1,
  SEND_MESS_SUCCESS: 2,
  FACEBOOK_24H : 2018278,
  FACEBOOK_REPPLY : 1545041,
  INTERNET: 1357045,
  OUTNEWLABEL: 1,
  OUTUPDATELABEL:2,
  SHIFT_CLOSED: 1,
  AUTOMATIC_SHIFT_LOCKING: 2,
  NOT_OPEN_CA: 3,
  REPORT: 'report',
  INTERNATIONAL: 'INTERNATIONAL',
  INTERNAL: 'INTERNAL',
  PAGE_IDS: [120638057800338, 175059809334230]
};
