<template>
  <v-app>
    <!-- <Menu /> -->
    <!-- <v-main> -->
    <router-view />
    <!-- </v-main> -->
  </v-app>
</template>

<script>
// import Menu from './components/Menu.vue';

import {constant} from "@/helpers/constant";

export default {
  name: "App",
  head: {
    title: {
      inner:  process.env.VUE_APP_PROJECT_TYPE === constant.INTERNATIONAL ? 'RinoEdu' : 'Bitu Chat - Quản lý hội thoại truyền thông',
      separator: ' ',
    },
    link: [
      {
        rel: 'icon',
        type: 'image/svg+xml',
        href: process.env.VUE_APP_PROJECT_TYPE === constant.INTERNATIONAL
            ? './icon.png'
            : './logo.png'
      }
    ]
  },

  data: () => ({
    totalUnRead: null,
  }),
  components: {
    // Menu,
  },
  sockets: {
    new_conversation: function () {
      // this.getConversationCreated();
    },
    update_conversation: function (data) {
      this.updateConversations(data);
    },
  },
  methods: {
    updateConversations(itemUser) {
      if (itemUser.unread_count == 1) {
        this.$store.dispatch(
          "SET_TOTALUNREAD",
          this.$store.state.totalUnRead + 1
        );
      }
    },
  },
};
</script>
