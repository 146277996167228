import Vue from 'vue'
import Vuex from 'vuex'
import TokenService from '../helpers/token'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: TokenService.getUser() || null,
    permissions: [],
    token: TokenService.getToken() || null,
    totalUnRead: null,
    namePage: null,
    phoneUser: null,
    detailPageShift: null,
    redirectDomain: {},
    listUserNeedRedirect: [],
    listUserException: [],
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_PERMISSION (state, payload) {
      state.permissions = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_TYPES (state, payload) {
      state.types = payload
    },
    SET_SCHOOLS (state, payload) {
      state.schools = payload
    },
    SET_PRODUCTS (state, payload) {
      state.products = payload
    },
    SET_SUBJECTS (state, payload) {
      state.subjects = payload
    },
    SET_TOTALUNREAD (state, payload) {
      state.totalUnRead = payload
    },
    SET_NAMEPAGE (state, payload) {
      state.namePage = payload
    },
    SET_PHONEUSER (state, payload) {
      state.phoneUser = payload
    },
    SET_PAGE_SHIFT (state, payload) {
      state.detailPageShift = payload
    },
    SET_REDIRECT_DOMAIN(state, payload) {
      state.redirectDomain = payload;
    },
    SET_LIST_USER_REDIRECT(state, payload) {
      state.listUserNeedRedirect = payload;
    },
    SET_LIST_USER_EXCEPTION(state, payload) {
      state.listUserException = payload;
    },

  },
  actions: {
    SET_PAGE_SHIFT (store, shift) {
      store.commit('SET_PAGE_SHIFT', shift)
    },
    SET_USER(store, user) {
      store.commit('SET_USER', user)
    },
    SET_PERMISSION (store, permissions) {
      store.commit('SET_PERMISSION', permissions)
    },
    SET_TOKEN (store, token) {
      store.commit('SET_TOKEN', token)
    },
    SET_TYPES(store, types) {
      store.commit('SET_TYPES', types)
    },
    SET_SCHOOLS(store, schools) {
      store.commit('SET_SCHOOLS', schools)
    },
    SET_PRODUCTS(store, products) {
      store.commit('SET_PRODUCTS', products)
    },
    SET_SUBJECTS(store, subjects) {
      store.commit('SET_SUBJECTS', subjects)
    },
    SET_TOTALUNREAD(store, totalUnRead) {
      store.commit('SET_TOTALUNREAD', totalUnRead)
    },
    SET_NAMEPAGE(store, namePage) {
      store.commit('SET_NAMEPAGE', namePage)
    },
    SET_PHONEUSER (store, phoneUser) {
      store.commit('SET_PHONEUSER', phoneUser)
    },
    setListUserNeedRedirect({ commit }, payload) {
      commit('SET_LIST_USER_REDIRECT', payload)
    },
    setRedirectDomain({ commit }, payload) {
      commit('SET_REDIRECT_DOMAIN', payload)
    },
    setListUserException({ commit }, payload) {
      commit('SET_LIST_USER_EXCEPTION', payload)
    },
  },
  modules: {

  }
})
