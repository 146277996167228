import Vue from 'vue'
import moment from 'moment'
Vue.mixin({
    methods: {
      formatDate(date) {
        if (date) {
          return moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
        }
        return '';
      },
      formatHour(date) {
        if (date) {
          return moment(date)
            .format('HH:mm');
        }
        return '';
      },
      compareMoment(a, b) {
        if(moment(a.ts).isAfter(b.ts)) {
          return -1;
        }

        return 1;
      },
      formatDateCustom(date) {
        if (date) {
          const iscurrentDate = moment(date).isSame(new Date(), "day");
          const isCurrentYear = moment(date).isSame(new Date(), "year");
          if(iscurrentDate) {
            return moment(date).utcOffset('+0700').format('HH:mm')
          }
          if(isCurrentYear) {
            return moment(date).utcOffset("+0700").format("HH:mm DD/MM");
          }
          return moment(date).utcOffset('+0700').format('DD/MM/YYYY')
        }
        return '';
      },
        formatPrice(value, endFix = '') {
        const valueABS = Math.abs(value);
        if (isNaN(valueABS))
          return value;
        if (valueABS > -1000 && valueABS < 1000) {
          return value;
        }
        const valueNumber = Intl.NumberFormat('en-US').format(valueABS);
        if (Number(value) < 0) {
          return `-${valueNumber}`;
        }
        if (endFix) {
          return  valueNumber + endFix;
        }
        return valueNumber;
      },
      formatDateVN(date) {
        if (date) {
          return moment(date)
            .format('DD/MM/YYYY');
        }
        return '';
      },
    },
  });
